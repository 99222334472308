let url = "";
let pumpFrontendUrl = "";
let pwaUrl = "";
let isGalaxPayInProd = false;
let politicasPrivacidadeURL = "";
let termosUsoURL = "";
let logsVersoesURL = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
	pumpFrontendUrl = "http://localhost:3001";
	pwaUrl = "http://localhost:3002";
	politicasPrivacidadeURL = 'http://localhost:3000/politica-de-privacidade';
	termosUsoURL = 'http://localhost:3000/termos-de-uso';
	logsVersoesURL = 'http://localhost:3000/versoes';
} else if (process.env.REACT_APP_STAGE === 'homolog') {
	url = "https://dev.api.cashbarber.com.br";
	pumpFrontendUrl = "https://dev.pump.cashbarber.com.br";
	pwaUrl = "https://dev.cashbarber.com.br";
	politicasPrivacidadeURL = 'https://dev.cashbarber.com.br/politica-de-privacidade';
	termosUsoURL = 'https://dev.cashbarber.com.br/termos-de-uso';
	logsVersoesURL = 'https://dev.painel.cashbarber.com.br/versoes';
} else {
	url = "https://api.cashbarber.com.br";
	pumpFrontendUrl = "https://pump.cashbarber.com.br";
	pwaUrl = "https://cashbarber.com.br";
	politicasPrivacidadeURL = 'https://painel.cashbarber.com.br/politica-de-privacidade';
	termosUsoURL = 'https://painel.cashbarber.com.br/termos-de-uso';
	logsVersoesURL = 'https://painel.cashbarber.com.br/versoes';
	isGalaxPayInProd = true;
}

export const Constants = {
	url: url,
	baseUrl: `${url}/api/painel`,
	pumpFrontendUrl: pumpFrontendUrl,
	pwaUrl: pwaUrl,
	isGalaxPayInProd,
	politicasPrivacidadeURL,
	termosUsoURL,
	logsVersoesURL,
	webPushPublicKey: 'BMf5GAN0LPUsaALujaqaM_i29Y0iPHlm3ncvgPYPdKJuielV2iU91qM-Iz0wuh8UXL7KV_jQYZyNzKvekuwpAsk',
};
